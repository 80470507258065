<template>
  <div id="page-lap-kunjungan-pasien">
    <b-container fluid>
      <b-row>
        <b-col cols="12" sm="12" md="12" lg="12" xl="12">
          <b-card>
            <b-row>
              <b-col cols="12" sm="12" md="12" lg="12" xl="12">
                  <b-form-group label-cols-md="4">
                  <template v-slot:label>
                    Dari
                  </template>
                  <div style="width:100%;display: flex;">
                    <b-form-select
                      :options="option_bulan"
                      size="sm"
                      v-model="data_search.bulan_awal"
                    ></b-form-select>

                    <b-form-select
                      :options="option_tahun"
                      size="sm"
                      v-model="data_search.tahun_awal"
                      class="ml-3"
                    ></b-form-select>
                  </div>
                  
                </b-form-group>

                <b-form-group label-cols-md="4">
                  <template v-slot:label>
                    Sampai
                  </template>
                  <div style="width:100%;display: flex;">
                    <b-form-select
                      :options="option_bulan"
                      size="sm"
                      v-model="data_search.bulan_akhir"
                    ></b-form-select>

                    <b-form-select
                      :options="option_tahun"
                      size="sm"
                      v-model="data_search.tahun_akhir"
                      class="ml-3"
                    ></b-form-select>
                  </div>
                </b-form-group>
              </b-col>
              <b-col cols="12" sm="12" md="12" lg="12" xl="12">
                <b-card>
                  <div style="width:100%;display:flex">
                    <div style="width:50%;height:30px;display:flex;justify-content:flex-start;align-items:center">
                      <h6 style="margin:0;font-weight:bold">Puskesmas</h6>
                    </div>
                    <div style="width:50%;height:30px;display:flex;justify-content:flex-end;align-items:center">
                      <b-button variant="outline-primary" size="sm">
                        <b-form-checkbox
                          v-model="checked"
                          @change="toggleSelect()"
                        >
                          <span class="pt-1">Pilih Semua</span>
                        </b-form-checkbox>
                      </b-button>
                    </div>
                  </div>
                  <hr>
                  <b-row style="padding-left:7.5px;margin-top:15px;">
                    <b-col v-for="(item, idx) in options_puskesmas" :key="idx" cols="12" sm="12" md="6" lg="4" xl="4">
                        <b-form-checkbox
                          v-model="item.checked"
                          @change="addSelect()"
                        >
                          {{ item.nama_puskesmas }}
                        </b-form-checkbox>
                    </b-col>
                    <!-- <b-col cols="12" sm="12" md="12" lg="6" xl="6">
                      <b-form-checkbox
                        id=""
                        name=""
                        value=""
                        unchecked-value=""
                      >
                        POLINDES BIDAN CANTIK
                      </b-form-checkbox>
                    </b-col>

                    <b-col cols="12" sm="12" md="12" lg="6" xl="6">
                      <b-form-checkbox
                        id=""
                        name=""
                        value=""
                        unchecked-value=""
                      >
                        PUSKEMAS DPP
                      </b-form-checkbox>
                    </b-col>

                    <b-col cols="12" sm="12" md="12" lg="6" xl="6">
                      <b-form-checkbox
                        id=""
                        name=""
                        value=""
                        unchecked-value=""
                      >
                        PUSTU PUSTU-1
                      </b-form-checkbox>
                    </b-col> -->
                  </b-row>
                </b-card>
                
              </b-col>
            </b-row>

            <b-row>
              <b-col cols="12" sm="12" md="12" lg="12" xl="12">
                <div style="display:flex;justify-content:flex-start">
                  <b-button @click="getDataKunjungan()" variant="primary" class="mr-2">Tampilkan</b-button>
                  <!-- <b-button variant="success" class="mr-2">Export to Excel</b-button> -->
                </div>
              </b-col>
            </b-row>

            <b-row>
              <b-col cols="12" sm="12" md="12" lg="12" xl="12">
                <hr>
              </b-col>
            </b-row>

            <b-row>
              <b-col cols="12" sm="12" md="12" lg="12" xl="12">
                <h4 style="text-align:center"><strong>LAPORAN BULANAN - UKP 5</strong></h4>
                <h5 style="text-align:center; margin-top:5px"><strong>Data Kematian di Puskesmas</strong></h5>
                <h6 style="text-align:center; margin-top:5px"><strong>Total Kematian {{ total }}</strong></h6>
              </b-col>
            </b-row>
            <b-row class="mt-4">
              <b-col md="9" offset-md="3">
                <b-form-group
                  label="Cari"
                  label-for="filter-input"
                  label-cols-md="3"
                  label-align-md="right"
                  label-size="md"
                >
                  <b-input-group size="md">
                
                  <b-form-input
                      id="filter-input"
                      v-model="filter"
                      type="search"
                      placeholder="Ketik disini untuk mencari ..."
                    ></b-form-input>
                  

                    <b-input-group-append>
                      <b-button
                        :disabled="!filter"
                        @click="filter = ''"
                        variant="danger"
                        >Hapus</b-button
                      >
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col cols="12" md="12" lg="12">
                <b-table
                  :items="items"
                  :fields="fields"
                  :current-page="currentPage"
                  :per-page="perPage"
                  :filter="filter"
                  :filter-included-fields="filterOn"
                  responsive
                  show-empty
                  small
                  @filtered="onFiltered"
                  bordered
                  striped
                  hover
                  :busy="tableBusy"
                >
                <!-- <template #cell(diagnosa)="item">
                    <b-row>
                        <b-col>
                            <ul>
                                <li v-for="(itemnya, index) in item.item.bulk_sub_tindakan_lab" :key="index">{{ itemnya.nama_sub_tindakan_lab }}</li>
                            </ul>
                        </b-col>
                    </b-row>
                </template>
                <template #cell(jenis_kasus)="item">
                    <b-row>
                        <b-col>
                            <ul>
                                <li v-for="(itemnya, index) in item.item.bulk_sub_tindakan_lab" :key="index">{{ itemnya.nama_sub_tindakan_lab }}</li>
                            </ul>
                        </b-col>
                    </b-row>
                </template> -->
                </b-table>
              </b-col>
            </b-row>

            <b-row>
              <b-col md="3">
                <b-form-group
                  label="Per Halaman"
                  label-for="per-page-select"
                  label-cols-md="6"
                  label-align-md="left"
                  label-size="md"
                  style="background-color: "
                >
                  <b-form-select
                    id="per-page-select"
                    v-model="perPage"
                    :options="pageOptions"
                    size="md"
                  ></b-form-select>
                </b-form-group>
              </b-col>
              <b-col md="6" offset-md="3">
                <b-pagination
                  v-model="currentPage"
                  :total-rows="totalRows"
                  :per-page="perPage"
                  align="fill"
                  size="sm"
                ></b-pagination>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  name: "laporan_ukp4",
  data() {
    return {
      showing: false,
      variant: "success",
      msg: "",
      value: null,
      data_search: {
        bulan_awal: null,
        tahun_awal: null,
        bulan_akhir: null,
        tahun_akhir: null,
      },
      select_puskesmas: [],
      checked: false,
      total: 0,
      options_puskesmas: [],
      option_bulan: [
        {value: 1, text: "Januari"},
        {value: 2, text: "Februari"},
        {value: 3, text: "Maret"},
        {value: 4, text: "April"},
        {value: 5, text: "Mei"},
        {value: 6, text: "Juni"},
        {value: 7, text: "Juli"},
        {value: 8, text: "Agustus"},
        {value: 9, text: "September"},
        {value: 10, text: "Oktober"},
        {value: 11, text: "November"},
        {value: 12, text: "Desember"},
      ],

      option_tahun: [
        {value: 2020, text: "2020"},
        {value: 2021, text: "2021"},
        {value: 2022, text: "2022"},
        {value: 2023, text: "2023"},
        {value: 2024, text: "2024"},
        {value: 2025, text: "2025"},
      ],

      fields: [
      {
        key: "no",
        label: "No",
        sortDirection: "desc",
        sortable: true,
        class: "table-number text-center",
      },

      {
        key: "nama_lengkap",
        label: "Nama",
        sortable: true,
        sortDirection: "desc",
        class: "text-left",
      },
      {
        key: "nik",
        label: "NIK",
        sortable: true,
        sortDirection: "desc",
        class: "text-left",
      },
      {
        key: "tanggal",
        label: "Tanggal",
        sortable: true,
        sortDirection: "desc",
        class: "text-left",
      },
      {
        key: "alamat_alm",
        label: "Alamat",
        sortable: true,
        sortDirection: "desc",
        class: "text-left",
      },
      {
        key: "jenis_kelamin_alm",
        label: "Jenis Kelamin",
        sortable: true,
        sortDirection: "desc",
        class: "text-left",
      },
      {
        key: "penyebab_tidak_langsung",
        label: "Sebab tidak langsung",
        sortable: true,
        sortDirection: "desc",
        class: "text-left",
      },
      {
        key: "penyembab_langsung",
        label: "Sebab Langsung",
        sortable: true,
        sortDirection: "desc",
        class: "text-left",
      },
      {
        key: "penyakit_penyerta",
        label: "Penyakit Penyerta",
        sortable: true,
        sortDirection: "desc",
        class: "text-left",
      },
    ],
    items: [],
    totalRows: 1,
    currentPage: 1,
    perPage: 10,
    pageOptions: [10, 25, 50, 100],
    filter: null,
    // filterOn: [],
    tableBusy: false,
    };
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => {
          return { text: f.label, value: f.key };
        });
    },
    filterOn() {
      // console.log(this.fields.map(el => el.key))
      return this.fields.map(el => el.key)
    },
  },
  activated(){
    this.totalRows = this.items.length;
    this.data_search.bulan_akhir = this.$moment(new Date()).format("MM")
    this.data_search.bulan_awal = this.$moment(new Date()).format("MM")
    this.data_search.tahun_akhir = this.$moment(new Date()).format("YYYY")
    this.data_search.tahun_awal = this.$moment(new Date()).format("YYYY")
    this.getDataKunjungan();
    this.getData()
  },
  // async mounted() {
  //   // Set the initial number of items
  //   this.totalRows = this.items.length;
  //   this.getDatas();
  // },
  methods: {
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    async getData(){
      let vm = this
      let puskesmas = await vm.$axios.post("/puskesmas/list")
      if(puskesmas.data.data.length){
          vm.options_puskesmas = puskesmas.data.data
          for (let i = 0; i < vm.options_puskesmas.length; i++) {
              let x = vm.options_puskesmas[i];
              x.checked = false
          }
      }
    },
    toggleSelect: function () {
          let select = this.checked;
          console.log(this.checked);
          this.options_puskesmas.forEach(function (data) {
              data.checked = select;
          });
          this.select_puskesmas = []
          for (let i = 0; i < this.options_puskesmas.length; i++) {
            let x = this.options_puskesmas[i];
            if(x.checked == true){
              this.select_puskesmas.push(x.puskesmas_id)
            }
          }
          console.log(this.select_puskesmas);
          this.$forceUpdate()
      },
      addSelect(){  
        let vm = this
        vm.select_puskesmas = []
        for (let i = 0; i < vm.options_puskesmas.length; i++) {
            let x = vm.options_puskesmas[i];
            if(x.checked == true){
              vm.select_puskesmas.push(x.puskesmas_id)
            }
          }
          console.log(vm.select_puskesmas);
      },
    getDataKunjungan(){
      let vm = this
      vm.$axios.post("/meninggal/laporan_meninggal", {
        bulan_awal: vm.data_search.bulan_awal,
        bulan_akhir: vm.data_search.bulan_akhir,
        tahun_awal: vm.data_search.tahun_awal,
        tahun_akhir: vm.data_search.tahun_akhir,
        bulk_puskesmas_id: vm.select_puskesmas
      })
      .then((res) => {
        console.log(res, 'ini respon');
        if(res.data.data.length){
          vm.items = res.data.data
          for (let i = 0; i < vm.items.length; i++) {
            let x = vm.items[i];
            x.no = i+1
            x.tanggal = vm.$moment(x.tanggal_alm).format("DD-MM-YYYY")
          }
          vm.total = vm.items.length
        }else {
          vm.items = []
        }
      })
      .catch((err) => {
        console.log(err);
      })
    },
    
    triggerAlert(event) {
      let vm = this;
      vm.$store.commit("set_alert", event);
    },
  },
};
</script>
<style>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
#page-lap-kunjungan-pasien .custom-table td{
  padding:0 !important;
  font-size:14px;
  font-weight:bold;
  border:hidden !important;
}
</style>
